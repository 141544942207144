import dayjs from "dayjs";
import PizZip from "pizzip";
import PizZipUtils from "pizzip/utils/index.js";
import Docxtemplater from "docxtemplater";
import angularParser from "docxtemplater/expressions.js";

import { saveAs } from "file-saver";

import sample from "../assets/docxTemplate/templater-v2.docx";

import { KPTYPE } from "../components/KPTComponents/KPTextform/KPTextform";
import { toPlural } from "./valueFormatter";
import { FORM_MODELS } from "../reducers/form.reducer";
import {
  CRUD_MODELS,
  EDUCATE_DEGREE_CONSTANTS,
  FORM_CONSTANTS,
} from "../constants";

const formatDate = (date) => {
  const tempDate = dayjs(date);
  if (tempDate.isValid()) {
    return tempDate.format("DD/MM/YYYY");
  }
};

const loadFile = (url, callback) => PizZipUtils.getBinaryContent(url, callback);

const formatDataCTKH = (listCTKH, key) => {
  const model = FORM_MODELS[key];

  // console.log(key, model);

  // tmpListCTKH

  // create issue-volumn-page variable from issue, volumn, page
  const tmpListCTKH = [];
  listCTKH.forEach((e) => {
    const _issue = e?.issue || "";
    const _volumn = e?.volumn || "";
    const _page = e?.page || "";

    let ivp = "";
    // if (_volumn) {
    //   ivp = ivp + " ," + _volumn;
    // }
    // if (_page) {
    //   ivp = ivp + " ," + _page;
    // }

    if (!_issue && !_volumn && !_page) {
      ivp = "";
    } else {
      ivp = _issue + "," + _volumn + "," + _page;
    }
    const tmp = {
      ...e,
      ivp: ivp,
    };
    tmpListCTKH.push(tmp);
  });

  return tmpListCTKH.map((e) => {
    const item = { ...e };

    for (let k in item) {
      if (model[k]?.type === KPTYPE.date) {
        // Date thì format lại
        item[k] = formatDate(item[k]);
      } else if (model[k]?.type === KPTYPE.select && k !== "phase") {
        // Select thì lấy title, trừ thằng `phase`
        if (k === "role") {
          item.isMain = item[k] === "main" ? "✔" : "";
        }
        item[k] =
          model[k].options.find((e) => e.value === item[k])?.title || "";
      } else {
        if (k == "jif") {
          item[k] = item[k] ? "IF = " + item[k] : "";
        } else {
          item[k] = item[k] || "";
        }
      }
    }

    return item;
  });
};

const CONSTANTS = {
  INDEX_TEXT: [
    "",
    "nhất",
    "hai",
    "ba",
    "tư",
    "năm",
    "sáu",
    "bảy",
    "tám",
    "chín",
    "mười",
  ],
};

const exportProfile = (form, stat = {}) => {
  // set profile.placeOfOrigin
  if (!form.profile.placeOfOrigin) {
    let placeOfOrigin = "";
    const commune = form.profile.commune || "";
    const district = form.profile.district || "";
    const province = form.profile.province || "";
    placeOfOrigin += commune;
    if (district) {
      if (placeOfOrigin) {
        placeOfOrigin += `, ${district}`;
      } else {
        placeOfOrigin += `${district}`;
      }
    }
    if (province) {
      if (placeOfOrigin) {
        placeOfOrigin += `, ${province}`;
      } else {
        placeOfOrigin += `${province}`;
      }
    }
    form.profile.placeOfOrigin = placeOfOrigin;
  }

  const exportData = {
    [toPlural(CRUD_MODELS.MONOGRAPH)]: { before: [], after: [] },
    [toPlural(CRUD_MODELS.SCIENTIFIC_TASK)]: { before: [], after: [] },
    [toPlural(CRUD_MODELS.SCIENTIFIC_RESEARCH)]: { before: [], after: [] },
    [toPlural(CRUD_MODELS.SOLUTION)]: formatDataCTKH(
      form[toPlural(CRUD_MODELS.SOLUTION)],
      CRUD_MODELS.SOLUTION
    ),
    [toPlural(CRUD_MODELS.RESEARCH_PROJECT)]: formatDataCTKH(
      form[toPlural(CRUD_MODELS.RESEARCH_PROJECT)],
      CRUD_MODELS.RESEARCH_PROJECT
    ),
  };

  exportData.category =
    FORM_CONSTANTS.CATEGORY_OPTIONS.find((e) => e.value === form.category)
      ?.title || "...";

  // Đối tượng đăng ký: Giảng viên {entityGV}; Giảng viên thỉnh giảng {entityGVTG};
  exportData.entityGV = form.entity === "gv" ? "☑" : "☐";
  exportData.entityGVTG = form.entity === "gvtg" ? "☑" : "☐";

  // Ngành: Giảng viên {major}; Chuyên ngành {specialization};
  exportData.major = FORM_CONSTANTS.MAJOR_OPTIONS.find(
    (e) => e.value === form.major
  )?.title;
  exportData.specialization = [
    ...FORM_CONSTANTS.SPECIALIZATION_OPTIONS.accounting,
    ...FORM_CONSTANTS.SPECIALIZATION_OPTIONS.business,
    ...FORM_CONSTANTS.SPECIALIZATION_OPTIONS.economics,
    ...FORM_CONSTANTS.SPECIALIZATION_OPTIONS.finance,
    ...FORM_CONSTANTS.SPECIALIZATION_OPTIONS.management,
  ].find((e) => e.value === form.specialization)?.title;

  // A. THÔNG TIN CÁ NHÂN
  // 1. Họ và tên người đăng ký: {profile.name}
  // 2. Ngày tháng năm sinh: {profile.birth}; Nam {profile.genderMale} Nữ  {profile.genderFemale}; Quốc tịch: {profile.nation};
  //    Dân tộc: {profile.ethnic}; Tôn giáo: {profile.religion}
  // 3. Đảng viên Đảng Cộng sản Việt Nam: {profile.isPartyMember}
  // 4. Quê quán: {profile.placeOfOrigin}
  // 5. Nơi đăng ký hộ khẩu thường trú: {profile.placeOfResidence}
  // 6. Địa chỉ liên hệ: {profile.contactAddress}
  // Điện thoại di động: {profile.phone}; E-mail: {profile.email}
  exportData.profile = {
    ...form.profile,
    birth: formatDate(form.profile.birth),
    genderMale: form.profile.gender === "male" ? "☑" : "☐",
    genderFemale: form.profile.gender === "female" ? "☑" : "☐",
    isPartyMember: form.profile.isPartyMember ? "☑" : "☐",
  };

  // 7. Quá trình công tác (công việc, chức vụ, cơ quan):
  // {#pastWorkingProcesses}
  //     + Thời gian công tác: {startTime}-{endTime}
  //     + Chức vụ: {position}-{unit}
  // {/pastWorkingProcesses}
  // - Từ 01/2010 đến nay: Giảng viên, Khoa Quản trị kinh doanh, Trường đại học Kinh tế Quốc dân
  //    Chức vụ hiện nay: {currentWorkingProcessPosition};
  //    Chức vụ cao nhất đã qua: {currentWorkingProcessHighestPosition}
  //    Cơ quan công tác hiện nay: {currentWorkingProcessUnit}
  //    Địa chỉ cơ quan: {currentWorkingProcessUnitAddress}
  //    Điện thoại cơ quan: {currentWorkingProcessUnitPhone}
  //    Thỉnh giảng tại cơ sở giáo dục (nếu có): {currentWorkingProcessUniversity}
  exportData.pastWorkingProcesses = form.pastWorkingProcesses.map((e) => ({
    ...e,
    startTime: formatDate(e.startTime),
    endTime: formatDate(e.endTime),
  }));
  exportData.currentWorkingProcessPosition =
    form.currentWorkingProcessPosition || "...";
  exportData.currentWorkingProcessHighestPosition =
    form.currentWorkingProcessHighestPosition || "...";
  exportData.currentWorkingProcessUnit =
    form.currentWorkingProcessUnit || "...";
  exportData.currentWorkingProcessUnitAddress =
    form.currentWorkingProcessUnitAddress || "...";
  exportData.currentWorkingProcessUnitPhone =
    form.currentWorkingProcessUnitPhone || "...";
  exportData.currentWorkingProcessUniversity =
    form.currentWorkingProcessUniversity || "...";

  // 9. Trình độ đào tạo
  // {#educateDegree}
  // - Được cấp {degreeType} {issueDate}
  // 	 + Số văn bằng: {number}
  //     + Ngành: {major}
  //     + Nơi cấp bằng: {issuePlace}
  // {/educateDegree}
  exportData.educateDegree = form.educateDegrees.map((e) => {
    const listIssueDate = formatDate(e.issueDate).split("/");
    return {
      ...e,
      degreeType: EDUCATE_DEGREE_CONSTANTS.TYPE_OPTIONS.find(
        (item) => item.value === e.degreeType
      )?.title,
      issueDate: `ngày ${listIssueDate[0]} tháng ${listIssueDate[1]} năm ${listIssueDate[2]}`,
    };
  });

  // 10. Đã được bổ nhiệm/công nhận chức danh Phó Giáo sư ngày {pgsStartTimeDate} tháng {pgsStartTimeMonth} năm {pgsStartTimeYear}, ngành {pgsMajor}
  exportData.pgsStartTimeDate =
    formatDate(form.pgsStartTime)?.split("/")?.[0] || "...";
  exportData.pgsStartTimeMonth =
    formatDate(form.pgsStartTime)?.split("/")?.[1] || "...";
  exportData.pgsStartTimeYear =
    formatDate(form.pgsStartTime)?.split("/")?.[2] || "...";
  exportData.pgsMajor = form.pgsMajor;

  // 11. Đăng ký xét đạt tiêu chuẩn chức danh Phó Giáo sư tại HĐGS cơ sở: {scientificCouncil}
  exportData.scientificCouncil = form.scientificCouncil || "...";

  // 12. Đăng ký xét đạt tiêu chuẩn chức danh Phó Giáo sư tại HĐGS ngành: {registeredMajor}
  exportData.registeredMajor = form.registeredMajor || "...";

  // 13. Các hướng nghiên cứu chủ yếu
  // {#researchDirections}
  // Hướng nghiên cứu thứ {id}: {title}
  // {description}
  // {/researchDirections}
  exportData.researchDirections = form.researchDirections.map((e, id) => ({
    ...e,
    id: CONSTANTS.INDEX_TEXT[id + 1],
  }));

  // 15. Khen thưởng
  // {#rewards}
  // Năm {year}
  // Nội dung khen thưởng {title}. Cấp: {level}
  // {/rewards}
  exportData.rewards = form.rewards;

  // 1. Tự đánh giá về tiêu chuẩn và nhiệm vụ của nhà giáo
  // {~~selfAssessment}
  //   exportData.selfAssessment = form.selfAssessment;

  // form.selfAssessment là html. remove các tag html và &nbsp;
  form.selfAssessment = form.selfAssessment?.replace(/-&nbsp;/g, "\n-");
  form.selfAssessment = form.selfAssessment?.replace(/- &nbsp;/g, "\n-");
  const regex = /(<([^>]+)>)/gi;
  exportData.selfAssessment = form.selfAssessment
    ?.replace(regex, "")
    .replace(/&nbsp;/g, "");

  // exportData.selfAssessment = form.selfAssessment;

  // 2. Tổng số năm thực hiện nhiệm vụ đào tạo: {totalEducateYear} năm {totalEducateMonth} tháng
  // {#educateProcess}
  // {id} {schoolYear} {main} {other} {master} {bachelor} {dh} {sdh} {dm}
  // {/educateProcess}
  exportData.totalEducateYear = form.totalEducateYear;
  exportData.totalEducateMonth = form.totalEducateMonth;
  exportData.educateProcess = {};

  // set educateProcess. if not exist, set default value
  // for each exportData.educateProcess, set default value if not exist
  [0, 1, 2, 3, 4, 5].forEach((id) => {
    exportData.educateProcess[`y${id}`] = {
      schoolYear: "",
      main: 0,
      other: 0,
      master: 0,
      bachelor: 0,
      dh: 0,
      sdh: 0,
      dm: 0,
    };
  });

  // [0, 1, 2, 3, 4, 5].forEach((id) => {
  //   exportData.educateProcess[`y${id}`] = form.educateProcess[`${id}`];
  // });

  // overwrite each element exportData.educateProcess with form.educateProcess
  [0, 1, 2, 3, 4, 5].forEach((id) => {
    exportData.educateProcess[`y${id}`] = {
      ...exportData.educateProcess[`y${id}`],
      ...form.educateProcess[`${id}`],
    };
  });

  // "-	Tham dự và trình bày báo cáo tại hội thảo quốc tế IBEA2015 (International conference on business, economics and accounting 2015) tổ chức tại Trường Đại học Kinh tế TP.HCM. -	Tham dự và trình bày báo cáo tại hội thảo quốc tế ICFE2015 (International Conference on Finance and Economics 2015) tại Trường Đại học Tôn Đức Thắng. -	Tham dự và trình bày báo cáo tại hội thảo quốc tế ICFE2016 (International Conference on Finance and Economics 2016) tại Trường Đại học Tôn Đức Thắng. -	Tham dự và trình bày báo cáo tại hội thảo quốc tế ICFE2018 (International Conference on Finance and Economics 2016) tại Trường Đại học Tôn Đức Thắng. -	Tham dự và trình bày tóm tắt báo cáo tại hội thảo quốc tế ICFME2023 (International Conference on Financial Management and Economics 2023) tại Trường Đại học Tôn Đức Thắng."

  // otherObject.dienGiai replace -\t with endline
  form.otherObject.dienGiai = form.otherObject.dienGiai?.replace(/-\t/g, "\n-");

  // 3. Ngoại ngữ
  exportData.foreignLanguageTrainingAbroad = {
    ...form.foreignLanguageTrainingAbroad,
    selected: form.foreignLanguageTrainingAbroad.selected ? "☑" : "☐",
    hocDaiHoc: form.foreignLanguageTrainingAbroad.hocDaiHoc ? "☑" : "☐",
    baoVeThs: form.foreignLanguageTrainingAbroad.baoVe == "ths" ? "☑" : "☐",
    baoVeTs: form.foreignLanguageTrainingAbroad.baoVe == "ts" ? "☑" : "☐",
    baoVeTskh: form.foreignLanguageTrainingAbroad.baoVe == "tskh" ? "☑" : "☐",
  };
  exportData.foreignLanguageTrainingInland = {
    ...form.foreignLanguageTrainingInland,
    selected: form.foreignLanguageTrainingInland.selected ? "☑" : "☐",
  };
  exportData.teachingInForeignLanguage = {
    ...form.teachingInForeignLanguage,
    selected: form.teachingInForeignLanguage.selected ? "☑" : "☐",
  };
  exportData.otherObject = {
    ...form.otherObject,
    selected: form.otherObject.selected ? "☑" : "☐",
  };
  [
    "foreignLanguageTrainingAbroad",
    "foreignLanguageTrainingInland",
    "teachingInForeignLanguage",
    "otherObject",
  ].forEach((key) => {
    for (let k in exportData[key]) {
      exportData[key][k] = exportData[key][k] || "...";
    }
  });
  exportData.englishCertificate = form.englishCertificate;

  // 4. Hướng dẫn NCS, HVCH/CK2/BSNT đã được cấp bằng/có quyết định cấp bằng
  // {#traineeGuidances}
  // {id} {title} {targetNcs} {targetHv} {roleMain} {roleOther} {startTime}-{endTime} {trainingBase} {issueDate}
  // {/traineeGuidances}
  exportData.traineeGuidances = form.traineeGuidances.map((e, id) => ({
    ...e,
    id,
    targetNcs: e.target === "ncs" ? "NCS" : "",
    targetHv: e.target === "hv" ? "HVCH/CK2/BSNT	" : "",
    roleMain: e.role === "main" ? "Chính" : "",
    roleOther: e.role === "other" ? "Phụ" : "",
    // roleMain: e.role === "main" ? "✔" : "",
    // roleOther: e.role === "other" ? "✔" : "",
    startTime: formatDate(e.startTime),
    endTime: formatDate(e.endTime),
    issueDate: formatDate(e.issueDate),
  }));

  // Xuất data CTKH
  [
    CRUD_MODELS.MONOGRAPH,
    CRUD_MODELS.SCIENTIFIC_TASK,
    CRUD_MODELS.SCIENTIFIC_RESEARCH,
  ].forEach((_key) => {
    const key = toPlural(_key);
    const listCTKH = formatDataCTKH(form[key], _key);
    // console.log(listCTKH);

    // const listCTKH = form[key].map((e, id) => {
    //   const model = FORM_MODELS[_key];
    //   const item = { ...e };

    //   for (let k in item) {
    //     if (model[k]?.type === KPTYPE.date) {
    //       // Date thì format lại
    //       item[k] = formatDate(item[k]);
    //     } else if (model[k]?.type === KPTYPE.select && k !== 'phase') {
    //       // Select thì lấy title, trừ thằng `phase`
    //       if (k === 'role') {
    //         item.isMain = item[k] === 'main' ? '✔' : '';
    //       }
    //       item[k] = model[k].options.find((e) => e.value === item[k])?.title || '';
    //     } else {
    //       item[k] = item[k] || '';
    //     }
    //   }

    //   return item;
    // });

    const listBefore = listCTKH?.filter((e) => e.phase === "before");
    const listBeforeDirectionId = listBefore.reduce((acc, curr) => {
      if (!acc.some((item) => item === curr.researchDirectionId)) {
        acc.push(curr.researchDirectionId);
      }
      return acc;
    }, []);

    const listAfter = listCTKH?.filter((e) => e.phase === "after");
    const listAfterDirectionId = listAfter.reduce((acc, curr) => {
      if (!acc.some((item) => item === curr.researchDirectionId)) {
        acc.push(curr.researchDirectionId);
      }
      return acc;
    }, []);

    exportData[key].before = listBeforeDirectionId.map((id) => ({
      researchDirection: form.researchDirections
        .map((e, id) => ({ ...e, orderIndex: CONSTANTS.INDEX_TEXT[id + 1] }))
        .find((e) => e.id === id),
      list: listBefore.filter((e) => e.researchDirectionId === id),
    }));

    exportData[key].after = listAfterDirectionId.map((id) => ({
      researchDirection: form.researchDirections
        .map((e, id) => ({ ...e, orderIndex: CONSTANTS.INDEX_TEXT[id + 1] }))
        .find((e) => e.id === id),
      list: listAfter.filter((e) => e.researchDirectionId === id),
    }));
  });
  // console.log(form, exportData);

  loadFile(sample, (error, content) => {
    if (error) throw error;

    const zip = new PizZip(content);
    const doc = new Docxtemplater(zip, {
      linebreaks: true,
      paragraphLoop: true,
      parser: angularParser,
      nullGetter() {
        return "";
      },
    });

    doc.render(exportData);

    const blob = doc.getZip().generate({
      type: "blob",
      mimeType:
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    });

    saveAs(blob, "File Chi tiết hồ sơ.docx");
  });
};

export default exportProfile;
