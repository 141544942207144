import React, { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  Box,
  Button,
  IconButton,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@mui/material";
import {
  Save,
  Drafts,
  GetApp,
  ArrowBack,
  ArrowForward,
} from "@mui/icons-material";

import theme from "../../theme";
import DangKyHoSoUpdate from "../../containers/DangKyHoSo/DangKyHoSoUpdate";

import { useStyles } from "./styles";
import { CRUD_LABELS, FORM_CONSTANTS, USER_CONSTANTS } from "../../constants";
import { jumpToReleventDiv } from "../../utils/anim";
import exportProfile from "../../utils/exportProfile";

const LayoutItem = ({ title, children = [], to = "#", tab = 0 }) => {
  const classes = useStyles();

  const handleClick = (id) => {
    jumpToReleventDiv(id);
    // window.history.pushState(null, null, `#${id}`);
  };

  return (
    <Fragment>
      {children.length > 0 ? (
        <Accordion
          defaultExpanded
          component={Box}
          classes={{
            root: classes.accordionRoot,
            expanded: classes.accordionExpanded,
          }}
        >
          <AccordionSummary
            classes={{
              root: classes.accordionSummaryRoot,
              content: classes.accordionSummaryContent,
              expanded: classes.accordionSummaryExpanded,
            }}
            sx={{ textAlign: "left" }}
          >
            <Typography
              sx={{
                cursor: "pointer",
                // overflow: 'hidden',
                // whiteSpace: 'nowrap',
                // textOverflow: 'ellipsis',
                color: theme.palette.grey[900],
              }}
            >
              <b>{title}</b>
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            classes={{
              root: classes.accordionDetailsRoot,
            }}
          >
            {children.map((e) => (
              <Typography
                key={e.key}
                sx={{
                  mb: 1,
                  cursor: "pointer",
                  color: e.color || theme.palette.grey[600],
                }}
                onClick={() => handleClick(e.key)}
              >
                <b>{e.title}</b>
              </Typography>
            ))}
          </AccordionDetails>
        </Accordion>
      ) : (
        <Typography
          sx={{
            mb: 1,
            cursor: "pointer",
            color: theme.palette.grey[900],
          }}
          onClick={() => handleClick(to)}
        >
          <b>{title}</b>
        </Typography>
      )}
    </Fragment>
  );
};

const DangKyHoSo = () => {
  const { id } = useParams();

  const form = useSelector((state) => state.formReducer.form);
  const profile = useSelector((state) => state.profileReducer.profile);

  const disabled =
    !!id && profile?.userRole !== USER_CONSTANTS.ROLE.super_admin;

  const [showLayout, setShowLayout] = useState(false);

  useEffect(() => {
    if (form) setShowLayout(true);
  }, [form]);

  const layout = (
    <Fragment>
      <LayoutItem title="A0. THÔNG TIN CHUNG" to="muc_a0" />
      <LayoutItem
        title="A. THÔNG TIN CÁ NHÂN"
        children={[
          { key: "mucA_muc1_6", title: "Mục 1-6" },
          { key: "mucA_muc7_8", title: "Mục 7-8" },
          { key: "mucA_muc9", title: "Mục 9" },
          { key: "mucA_muc10_13", title: "Mục 10-13" },
          { key: "mucA_muc14", title: "Mục 14" },
          { key: "mucA_muc15", title: "Mục 15" },
          { key: "mucA_muc16", title: "Mục 16" },
        ]}
      />
      <LayoutItem
        title="B. Tự khai theo tiêu chuẩn chức danh GS/PGS"
        children={[
          { key: "mucB_muc1_2", title: "Mục 1-2" },
          { key: "mucB_muc3", title: "Mục 3" },
          { key: "mucB_muc4", title: "Mục 4" },
          { key: "mucB_muc5", title: "Mục 5" },
          { key: "mucB_muc6", title: "Mục 6" },
          { key: "mucB_muc7_1", title: "Mục 7.1" },
          { key: "mucB_muc7_2", title: "Mục 7.2" },
          { key: "mucB_muc7_3", title: "Mục 7.3" },
          { key: "mucB_muc8", title: "Mục 8" },
          { key: "mucB_muc9", title: "Mục 9" },
        ]}
      />
      <LayoutItem title="C. Báo cáo khoa học tổng quan" to="muc_c" />
      <LayoutItem title={"D. " + CRUD_LABELS.program} to="muc_d" />
      <LayoutItem
        title="E. Minh chứng bản xác nhận thực hiện nhiệm vụ đào tạo và nghiên cứu khoa học của giảng viên"
        to="muc_e"
      />
    </Fragment>
  );

  return (
    <Fragment>
      <Box
        sx={{
          mt: 8,
          display: { xs: "none", md: "block" },
          position: "fixed",
          flexDirection: "column",
          // minHeight: 'calc(100vh - 64px - 64px - 16px)',
          // maxHeight: 'calc(100vh - 64px - 64px - 16px)',
          minWidth: showLayout ? 210 : 0,
          maxWidth: showLayout ? 210 : 0,
        }}
      >
        <Box>
          <IconButton onClick={() => setShowLayout(!showLayout)}>
            {showLayout ? <ArrowBack /> : <ArrowForward />}
          </IconButton>
          <IconButton onClick={() => exportProfile(form)}>
            <GetApp />
          </IconButton>
        </Box>
        <Box
          sx={{
            overflow: "auto",
            maxHeight: "calc(100vh - 64px - 64px - 16px - 80px - 48px)",
          }}
        >
          {layout}
        </Box>
        <Box sx={{ mt: 1, display: showLayout ? "block" : "none" }}>
          <Button
            fullWidth
            color="info"
            type="submit"
            form="form-dkhs"
            variant="contained"
            sx={{ mb: 1 }}
            disabled={disabled}
            startIcon={<Drafts />}
            data-status={FORM_CONSTANTS.STATUS_MAP.draft.value}
          >
            Lưu bản nháp
          </Button>
          <Button
            fullWidth
            type="submit"
            form="form-dkhs"
            variant="contained"
            disabled={disabled}
            startIcon={<Save />}
            data-status={FORM_CONSTANTS.STATUS_MAP.submitted.value}
          >
            Nộp hồ sơ
          </Button>
        </Box>
      </Box>
      <Box
        sx={{
          pr: !showLayout ? "100px" : 0,
          pl: !showLayout ? "100px" : "226px",
          display: { xs: "none", md: "block" },
        }}
      >
        <DangKyHoSoUpdate />
      </Box>
      <Box
        sx={{
          display: { xs: "block", md: "none" },
        }}
      >
        <DangKyHoSoUpdate />
      </Box>
    </Fragment>
  );
};

export default DangKyHoSo;
